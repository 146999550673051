import React, { useState, useEffect } from "react";
import TimePicker from "react-time-picker-input";

const TimeSelect = ({ input, label, simple, meta: { error, touched, warning } }) => {
  const [value, setValue] = useState(input.value || "00:00"); // Gebruik input.value als initiële waarde, anders fallback naar "00:00"

  useEffect(() => {
    // Alleen updaten als input.value verandert en verschilt van de huidige waarde
    if (input.value && input.value !== value) {
      setValue(input.value);
    }
  }, [input.value, value]);

  const handleSelect = (selectedValue) => {
    setValue(selectedValue);
    input.onChange(selectedValue);
  };

  return (
    <div>
      {!simple && label && <label>{label}</label>}
      <div>
        <TimePicker
          value={value}
          onChange={handleSelect}
          format="HH:mm" // 24-uurs formaat
          className="form-control"
        />
        {touched && (
          <>
            {error && <span className="text-danger">{error}</span>}
            {warning && <span>{warning}</span>}
          </>
        )}
      </div>
    </div>
  );
};

export default TimeSelect;
