import React from "react";
import { Field, reduxForm, FieldArray, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import timeSelect from "../../../Components/Forms/time";
import dateSelect from "../../../Components/Forms/date";
import { renderField, renderselect } from "../../../Components/Forms/renders";
import switcbutton from "../../../Components/Forms/switch";
import PrijzenForm from "./prijzen";
import TijdsslotenForm from "../Components/tijdsloten.jsx";

const BeschikbaarheidEditForm = ({ handleSubmit, formValues, verwijderen, initialValues }) => {
  const isPrijzenChecked = formValues && formValues.betaald;
  return (
    <form onSubmit={handleSubmit}>
      <div className="box-body">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                Datum en tijden aanpassen
              </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
              <div className="accordion-body">
                <Field label="Kies een datum" validate={required({ msg: "Datum is verplicht" })} component={dateSelect} name="datum" />
                <Field label="Kies starttijd" component={timeSelect} name="starttime" />
                <Field label="Kies en einddtijd" component={timeSelect} name="endtime" />
                <Field
                  className="form"
                  name="status"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Status van de beschikbaarheid"
                  component={renderselect}
                  options={{
                    actief: "Actief",
                    vol: "Volgeboekt",
                    geannuleerd: "Geannuleerd",
                    verplaats: "Verplaatst",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                Beschikbaarheid aanpassen
              </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
              <div className="accordion-body">
                <Field name="beschikbaarheid.personenmax" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Maximaal aantal deelnemers" />
                <Field name="beschikbaarheid.personenmin" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Minimaal aantal deelnemers" />
                <Field name="beschikbaarheid.eind" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Hoeveel uren vooraf aanvang mag er een reservering worden gemaakt (0 is geen restrictie)" />
                <Field name="beschikbaarheid.start" type="number" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Hoeveel dagen mag er een reservering worden gemaakt (0 is geen restrictie)" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Starttijd *</th>
                      <th>Minimaal aantal deelnemers *</th>
                      <th>Maximaal aantal deelnemers *</th>
                    </tr>
                  </thead>
                  <FieldArray name="beschikbaarheid.tijdssloten" component={TijdsslotenForm} />
                </table>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                Locatie aanpassen
              </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
              <div className="accordion-body">
                <Field name="location.label" type="input" component={renderField} label="Titel" />
                <Field name="location.street" type="input" component={renderField} label="Straat" />
                <Field name="location.housenr" type="input" component={renderField} label="Huisnummer" />
                <Field name="location.zipcode" type="input" component={renderField} label="Postcode" />
                <Field name="location.city" type="input" component={renderField} label="Plaats" />
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                Prijzen aanpassen
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse">
              <div className="accordion-body">
                <Field name="betaald" type="input" component={switcbutton} label="Deze ervaring is betaald" />
                {isPrijzenChecked && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Naam *</th>
                        <th>Beschrijving *</th>
                        <th>Leeftijd van </th>
                        <th>Leeftijd tot</th>
                        <th>Prijs *</th>
                      </tr>
                    </thead>
                    <FieldArray name={`prijzen`} component={PrijzenForm} />
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" onClick={() => verwijderen(initialValues._id)} className="btn btn-sm btn-danger m-3">
        Verwijderen
      </button>
      <button type="submit" className="btn btn-primary m-3">
        Wijzigen
      </button>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "edit_bschrikbaarheid", // a unique identifier for this form
})(BeschikbaarheidEditForm);

const mapStateToProps = (state, ownProps) => ({
  initialValues: ownProps.beschikbaarheid, // pull initial values from the "beschikbaarheid" prop
  formValues: getFormValues("edit_bschrikbaarheid")(state), // get current form values
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
