import React, { useEffect } from "react";
import { Field, reduxForm, FieldArray, change } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import timeSelect from "../../../Components/Forms/time";
import dateSelect from "../../../Components/Forms/date";
import TijdsslotenForm from "../Components/tijdsloten.jsx";

const BeschikbaarheidForm = ({ handleSubmit, ervaring, dispatch, formValues }) => {
  useEffect(() => {
    // Gebruik change om de tijdssloten in te stellen op basis van ervaring
    if (ervaring?.beschikbaarheid?.tijdssloten) {
      dispatch(
        change(
          "insert_bschrikbaarheid_arrangementen",
          "beschikbaarheid.tijdssloten",
          ervaring.beschikbaarheid.tijdssloten
        )
      );
    }
  }, [ervaring, dispatch]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="box-body">
        <Field
          label="Kies een datum"
          validate={required({ msg: "Datum is verplicht" })}
          component={dateSelect}
          name="datum"
        />
        <Field label="Kies starttijd" component={timeSelect} name="starttime" />
        <Field label="Kies een eindtijd" component={timeSelect} name="endtime" />
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Starttijd *</th>
              <th>Minimaal aantal deelnemers *</th>
              <th>Maximaal aantal deelnemers *</th>
            </tr>
          </thead>
          <FieldArray name="beschikbaarheid.tijdssloten" component={TijdsslotenForm} />
        </table>
      </div>
      <button type="submit" className="btn btn-primary">Opslaan</button>
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "insert_bschrikbaarheid_arrangementen", // een unieke identifier voor dit formulier
})(BeschikbaarheidForm);

const mapStateToProps = (state, ownProps) => ({
  formValues: state.form.insert_bschrikbaarheid_arrangementen?.values || {}, // huidige formulierwaarden
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);