import { AUTH_USER, AUTH_VERIFY, AUTH_ERROR, UNAUTH_USER, AUTH_USER_PROFILE, SET_USERS, AUTH_WEBSITE_CONFIG } from './types';

async function handleResponse(response) {
  if (response.ok) {
    return await response.json();
  } else {
    const error = new Error(response.statusText || "Request failed");
    error.status = response.status;

    try {
      const errorBody = await response.json();
      error.response = errorBody;
    } catch (e) {
      error.response = response;
    }

    throw error;
  }
}

export function config() {
  return async dispatch => {
    try {
      const response = await fetch('/api/config', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        }
      });
      const data = await handleResponse(response);
      dispatch({ type: AUTH_WEBSITE_CONFIG, config: data });
      return true;
    } catch (err) {
      return dispatch(authError("Om toegang te hebben moet je ingelogd zijn"));
    }
  };
}

export function Login(userData) {
  return async dispatch => {
    try {
      const response = await fetch('/api/signin', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const data = await handleResponse(response);
      dispatch({ type: AUTH_USER, user: data });
      return data;
    } catch (err) {
      dispatch(authError(err.response?.statusMessage || err.statusMessage || "Er is een fout opgetreden"));
    }
  };
}

export function confirmationSms(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/signin/verification', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const result = await handleResponse(response);
      dispatch({ type: AUTH_USER, user: result });
      return result;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}

export function signupUser(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/signup', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      await handleResponse(response);
      return true;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}

export function getprofile() {
  return async dispatch => {
    try {
      const response = await fetch('/api/user', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      dispatch({ type: AUTH_USER_PROFILE, user: data.user });
      return true;
    } catch (err) {
      return dispatch(authError("Om toegang te hebben moet je ingelogd zijn"));
    }
  };
}

export function authError(error) {
  return { type: AUTH_ERROR, payload: error };
}

export function signoutUser() {
  return dispatch => {
    localStorage.removeItem('token');
    dispatch({ type: UNAUTH_USER });
  };
}

export function confirmationPost(data) {
  return async dispatch => {
    try {
      const response = await fetch(`/api/signup/verification/${data.code}`, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      const result = await handleResponse(response);
      dispatch({ type: AUTH_VERIFY, msg: result.msg });
      return true;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}

export function NewPassword(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/login/forget/verification', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const result = await handleResponse(response);
      dispatch({ type: AUTH_VERIFY, msg: result.msg });
      return true;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}

export function NewPasswordlog(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/profile/changepass', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const result = await handleResponse(response);
      dispatch({ type: AUTH_VERIFY, msg: result.msg });
      return true;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}

export function loginforget(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/signin/forget', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const result = await handleResponse(response);
      dispatch({ type: AUTH_VERIFY, msg: result.msg });
      return true;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}

export function Sendmail(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/signin/sendvmail', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const result = await handleResponse(response);
      dispatch({ type: AUTH_VERIFY, msg: result.msg });
      return true;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}

export function fetchusers() {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/users', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const data = await handleResponse(response);
      dispatch({ type: SET_USERS, user: data.results });
      return true;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}

export function updateuser(data) {
  return async dispatch => {
    try {
      const response = await fetch('/api/admin/user', {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem('token')
        }
      });
      const result = await handleResponse(response);
      dispatch({ type: SET_USERS, user: result.results });
      return true;
    } catch (err) {
      dispatch(authError(err.response?.message || err.statusText || "Er is een fout opgetreden"));
    }
  };
}