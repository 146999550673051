import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import MapsForm from "../../../Components/Forms/maps";
import AdressForm from "../../../Components/Forms/Adress";
import { renderField, renderTextField } from "../../../Components/Forms/renders";
import UploadForm from "../../../Components/Forms/upload";
import Tags from "../../../Components/Forms/tags";

const WaarItems = ({ fields, ontvanggegevs, replacemap }) => {
  const [selectedPlace, setSelectedPlace] = useState([{ lat: 52.347809, lon: 5.98399 }]);

  return (
    <div className="card">
      {fields.map((waar, index) => (
        <div className="row p-4" key={index}>
          <div className="col-sm-7">
            <h4 className="info-text">Zoek hier de gegevens in van het adres</h4>

            <Field name={`${waar}.zoeken`} ontvanggegevs={ontvanggegevs} component={AdressForm} label="Vind het adres" className="form-control" />
            <Field name={`${waar}.label`} type="input" component={renderField} label="Titel" />
            <div className="row">
              <div className="col-12 col-lg-8">
                <Field name={`${waar}.street`} type="input" component={renderField} label="Straat" validate={required({ msg: "Dit veld is verplicht" })} />
              </div>
              <div className="col-12 col-lg-4">
                <Field name={`${waar}.housenr`} type="input" component={renderField} label="Huisnummer" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-4">
                <Field name={`${waar}.zipcode`} type="input" component={renderField} label="Postcode" />
              </div>
              <div className="col-12 col-lg-6">
                <Field name={`${waar}.city`} type="input" component={renderField} label="Plaats" validate={required({ msg: "Dit veld is verplicht" })} />
              </div>
              <div className="col-12 col-lg-2">
                <Field name={`${waar}.country`} type="input" component={renderField} label="Land" validate={required({ msg: "Dit veld is verplicht" })} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <Field label="Latitude" name={`${waar}.coordinates[0].lat`} type="input" component={renderField} validate={required({ msg: "Dit veld is verplicht" })} />
              </div>
              <div className="col-12 col-lg-6">
                <Field label="Longitude" name={`${waar}.coordinates[0].lon`} type="input" component={renderField} validate={required({ msg: "Dit veld is verplicht" })} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <Field label="E-mail adres" name={`${waar}.email`} type="input" component={renderField} validate={required({ msg: "Dit veld is verplicht" })} />
              </div>
              <div className="col-12 col-lg-6">
                <Field label="Telefoonnummer" name={`${waar}.telefoon`} type="input" component={renderField} validate={required({ msg: "Dit veld is verplicht" })} />
              </div>
              <div className="col-12 col-lg-12">
                <Field label="E-mailadres bevestiging sturen" name={`${waar}.bevestiging`} type="input" component={Tags} validate={required({ msg: "Dit veld is verplicht" })} />
              </div>
            </div>
            <Field name={`${waar}.beschrijving`} type="input" component={renderTextField} label="Beschrijving van de locatie" />
            <Field name={`${waar}.images`} type="input" component={UploadForm} label="Foto's van de locatie" />
            <div className="mt-4">
              <button className="btn btn-danger" onClick={() => fields.remove(index)}>
                <i className="fa fa-minus-circle"></i> Verwijder locatie
              </button>
            </div>
          </div>
          <div className="col-sm-5">
            <h4 className="info-text">Zoek op de kaart het adres</h4>
            <div style={{ height: "auto" }}>
              <Field name={`${waar}.coordinates[0]`} replacemap={replacemap} newlatlon={selectedPlace[index]} component={MapsForm} getdata={selectedPlace[index]} label="Vind het adres" className="form-control" />
            </div>
          </div>
          <hr className="mt-4" />
        </div>
      ))}
      <div className="card-footer">
        <button type="button" className="btn btn-success" onClick={() => fields.push({})}>
          Locatie Toevoegen
        </button>
      </div>
    </div>
  );
};
export default WaarItems;
