import { GET_ARRANGEMENTEN, ADD_ARRANGEMENTEN, ERROR_ARRANGEMENTEN, SET_ARRANGEMENTEN, SET_BESCHIKBAARHEID, SET_BESCHIKBAARHEIDEN, SET_ALLEDAGEN, DELETE_BESCHIKBAARHEID } from "./actions";
export default function arrangementen(
  state = {
    items: [],
    error: {},
    item: [],
    beschikbaarheid: {},
    beschikbaarheden: [],
    alledagen: false,
  },
  action = {}
) {
  switch (action.type) {
    case ADD_ARRANGEMENTEN:
      return { ...state, items: [...state.items, action.results] };
    case ERROR_ARRANGEMENTEN:
      return { ...state, error: action.payload };
    case SET_ALLEDAGEN:
      return { ...state, alledagen: action.payload };
    case GET_ARRANGEMENTEN:
      return { ...state, items: action.results };
    case SET_ARRANGEMENTEN:
      return { ...state, item: action.results };
    case SET_BESCHIKBAARHEID:
      return { ...state, beschikbaarheid: action.results };
    case DELETE_BESCHIKBAARHEID:
      return { ...state, beschikbaarheid: {} };

    case SET_BESCHIKBAARHEIDEN:
      return { ...state, beschikbaarheden: action.results };

    default:
      return state;
  }
}
