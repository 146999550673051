import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updatearrangementen, getarrangementen } from "../Reducers/actions";
import { message } from "../../../Reducers/loading/actions";
import LogboekEditForm from "../Components/waar";
import { Link, useParams, useNavigate } from "react-router-dom";
const ErvaringenEditWaarPage = ({ updatearrangementen, getarrangementen, message }) => {
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setLoaded(false);
    getarrangementen(params.id).then((data) => {
      if (data.results) {
        setLoaded(true);
      } else {
        message(2000, "warning", "Er is een fout opgetreden bij het laden van de activiteit").then((data) => {
          navigate("/logboek");
        });
      }
    });
  }, [getarrangementen, params.id, message]);

  const submit = (vals) => {
    console.log(vals);
    updatearrangementen(params.id, vals).then((data) => {
      if (data.type === "FACILIEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        message(2000, "success", "De ervaring is met succes aangepast").then((data) => {
          navigate("/arrangementen/edit/media/" + params.id);
        });
      }
    });
  };
  

  return (
    <div className="box box-default">
      <div className="box-header with-border">
      <h3 className="box-title"> Arrangement wijzigen</h3>
      </div>
      <div className="row">
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body wizard">
              <ul className="nav nav-justified thumbnail setup-panel">
                <li className="flex-item">
                  <Link to={"/arrangementen/edit/" + params.id}>Wat</Link>
                </li>
                <li className="active flex-item">
                  <Link to={"/arrangementen/edit/waar/" + params.id}>Waar</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/arrangementen/edit/media/" + params.id}>Media</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/arrangementen/edit/items/" + params.id}>Items</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/arrangementen/edit/beschikbaarheid/" + params.id}>Beschikbaarheid</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/arrangementen/edit/eigenschappen/" + params.id}>Eigenschappen</Link>
                </li>
                <li className="flex-item">
                  <Link to={"/arrangementen/edit/communicatie/" + params.id}>Communicatie</Link>
                </li>
              </ul>
              {loaded && <LogboekEditForm onSubmit={submit} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { username: state.auth.user, arrangementen: state.arrangementen.item };
}

export default connect(mapStateToProps, {
  updatearrangementen,
  getarrangementen,
  message,
})(ErvaringenEditWaarPage);
