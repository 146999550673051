import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { Upload } from "../../Pages/Items/Reducers/items/actions";

const UploadFormSingle = ({ label, Upload, onClose, input }) => {
  const [disabled, setDisabled] = useState(true);
  const [files, setFiles] = useState({});
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});

  useEffect(() => {
    if (input.value && input.value.thumbnail && input.value.thumbnail.small) {
      const updatedFiles = {
        ...input.value, // Kopieer alle eigenschappen van het bestaande bestand
        preview: input.value.thumbnail.small, // Voeg de preview eigenschap toe
      };

      setFiles(updatedFiles); // Update de state met de nieuwe array
    }
  }, [input.value]);

  const onDrop = (files, rejectedFiles) => {
    let errors = {};
    setFiles(
      Object.assign(files[0], {
        preview: URL.createObjectURL(files[0]),
      })
    );
    setErrors(errors);
  };

  const handleUpload = async () => {
    try {
      const data = await Upload({ file: files });

      if (data.type === "ITEM_ERROR") {
        const newErrors = { message: data.payload };
        setErrors(newErrors);
        return true;
      } else {
        input.onChange(data);
      }

      setFiles({})
      setSuccess({ message: "Uploaden is gelukt" });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = () => {
    setFiles({});
  };
  return (
    <div>
      <label>{label}</label>
      <div>
        <Dropzone multiple={false} onDrop={onDrop} minSize={10000}>
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                minHeight: "100px",
                borderWidth: "2px",
                borderColor: "rgb(102, 102, 102)",
                borderStyle: "dashed",
                borderRadius: "5px",
                display: "inline-block",
                width: "100%",
              }}
            >
              <div
                style={{
                  minHeight: "100px",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p>Kies je bestand of sleep hem hier naar toe</p>
              </div>
            </section>
          )}
        </Dropzone>

        <ul className="users-list clearfix">
          {files.preview && (
            <li key={files.name}>
              <button type="button" className="close" onClick={() => handleDelete()} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <img src={files.preview} alt="Afbeelding" />
              <span className="users-list-date">{files.name}</span>
            </li>
          )}
        </ul>
        {errors && <div className="text-danger">{errors.message}</div>}
        {success && <div className="text-success">{success.message}</div>}
        <input type="button" onClick={handleUpload} className="btn btn-next btn-fill btn-success btn-wd btn-sm" value="Uploaden" />
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { Upload })(UploadFormSingle);
