import React, { useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import Rodal from "rodal";
import UploadForm from "../../../Components/Forms/upload";

import SortableComponent from "../../../Components/Gallery/sort";
import { renderField } from "../../../Components/Forms/renders";

const SyncValidationForm = ({ handleSubmit, dispatch, initialValues }) => {
  const [visible, setVisible] = useState(false);

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <h4 className="info-text">Media gegevens</h4>
        </div>
      </div>
      <div className="box">
        <div className="box with-border">
          <h3 className="box-title">Klik hier om de foto's te uploaden </h3>
          <div className="box-tools pull-right">
            <button type="button" className="btn btn-primary btn flex-end uploadbtn" onClick={show}>
              Upload bestanden
            </button>
          </div>

          <div className="box-body">
            <div className="box">
              <Field name="images" component={SortableComponent} />
            </div>
            <Rodal visible={visible} onClose={hide} width={800} height={500}>
              <Field name="images" onClose={hide} component={UploadForm} label="Kies bestanden" isModal={true} />
            </Rodal>
          </div>
        </div>
      </div>

      <div className="box">
        <div className="box with-border">
          <h3 className="box-title">Youtube Video</h3>
          <div className="box-body">
            <Field name="media.video.url" label="URL van Youtube Video" component={renderField} />
          </div>
        </div>
      </div>

      <div className="box">
        <div className="box with-border">
          <h3 className="box-title">Skyfocus</h3>
          <div className="box-body">
            <Field name="media.tours.url" label="URL van skyfocus" component={renderField} />
          </div>
        </div>
      </div>
      <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
    </form>
  );
};

const ReduxFormSensorEdit = reduxForm({
  form: "categorie_edit_media", // a unique identifier for this form
})(SyncValidationForm);

const mapStateToProps = (state) => ({
  initialValues: state.ervaringen.item, // pull initial values from account reducer
});

export default connect(mapStateToProps)(ReduxFormSensorEdit);
