import React, { useState, useEffect } from "react";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { SingleDatePicker } from "react-dates";

const DateSelect = (props) => {
  const [value, setValue] = useState(moment());
  const [date, setDate] = useState(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (props.input.value) {
      if (props.dateformat) {
        setDate(moment(props.input.value, props.dateformat));
      } else {
        setDate(moment(props.input.value));
      }
    } else if (props.defaultValue) {
      setDate(moment(props.defaultValue, "DD/MM/YYYY"));
      props.input.onChange({ value: props.defaultValue });
    }
  }, []);

  const handleSelect = (val) => {
    const selectedValue = moment(val, "DD/MM/YYYY");
    if (selectedValue.isValid()) {
      setValue(selectedValue);
      if (props.dateformat) {
        props.input.onChange(moment(selectedValue).format(props.dateformat));
      } else {
        props.input.onChange(selectedValue);
      }
    }
  };

  const isOutsideRange = (day) => {
    const today = moment();
    if (props.mindate && props.maxdate) {
      return (day.isBefore(today) && !props.mindate) || (day.isAfter(today) && !props.maxdate);
    }
    if (props.mindate) {
      return day.isAfter(today); // Only allow past dates
    }
    if (props.maxdate) {
      return day.isBefore(today); // Only allow future dates
    }
    return false; // No restriction
  };


  const {
    label,
    meta: { error, touched, warning },
  } = props;

  return (
    <div>
      {label && <label>{label}</label>}
      <div>
        <SingleDatePicker
          displayFormat={() => "DD/MM/YYYY"}
          numberOfMonths={1}
          date={date}
          placeholder={label}
          onDateChange={(selectedDate) => {
            setDate(selectedDate);
            handleSelect(selectedDate);
          }}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          isOutsideRange={isOutsideRange}
        />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

export default DateSelect;
